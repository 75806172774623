<template>
	<div id="remember_link_hint">
		<p id="remember_link_hint_hl"><b>Hint:</b></p>
		<p>Bookmark your personal hyperchannel link or check your email (spam) inbox.</p>
	</div>
</template>

<style>
	#remember_link_hint {
		color: var(--main-darkgray-color);
		margin-bottom: 0.5rem;
		font-size: 0.7em;
		position: relative;
		width: 18rem;
		margin: 2rem auto 3rem auto;
		display: flex;
	}
	#remember_link_hint_hl {
		margin-right: .6em;
	}
</style>